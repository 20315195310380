import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import './App.css';

import Img_Game_Logo from './assets/img/Game_Logo.png';
import Img_Android_Logo from './assets/img/android_icon.png';
import Img_Apple_Logo from './assets/img/apple_icon.png';

function App() {
  return (
    <div className="App">
      <Container className='pt-3'>
        <Row>
          <Col className='animate__animated animate__bounceIn'>
            <Image src={Img_Game_Logo} className='center game-logo'/>
          </Col>
        </Row>
        <Row className='text-center pb-4'>
          <Col className='animate__animated animate__rotateIn animate__delay-1s'>
            <h2 className='sub-title'>Juega y no dejes de brincar para llegar lo mas lejos posible</h2>
          </Col>
        </Row>
        <Row className='text-center'>
          <Col className='d-flex p-2 animate__animated animate__bounceInLeft animate__delay-2s'>
            <Button className='m-auto btn-download' variant='success' href="http://frenzygamesstd.com/downloads/bunnyadventures/android.apk">
              <Image src={Img_Android_Logo} className='center btn-logo'/>
              <strong className='font-game'>Descargar</strong>
            </Button>
          </Col>
          <Col className='d-flex p-2 animate__animated animate__bounceInRight animate__delay-3s'>
            <Button className='m-auto btn-download' variant='success' href='https://apps.apple.com/app/bunny-adventures/id6479624329'>
              <Image src={Img_Apple_Logo} className='center btn-logo'/>
              <strong className='font-game'>Descargar</strong>
            </Button>
          </Col>
        </Row>
        <Row className='text-center p-4 animate__animated animate__bounceInUp animate__delay-4s'>
          <Col className='animate__animated animate__pulse animate__infinite'>
            <h2 className='sub-title gold'>¡Juega completamente gratis!</h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
